import { useRouteLoaderData } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useEffect } from 'react'
import type { loader } from '~/root'

export const useSentry = () => {
  const rootLoaderData = useRouteLoaderData<typeof loader>('root')

  useEffect(() => {
    rootLoaderData?.authenticatedUser
      ? Sentry.setUser({ email: rootLoaderData.authenticatedUser.email })
      : Sentry.setUser(null)
  }, [rootLoaderData?.authenticatedUser])

  return null
}
