import { type Toast, toast as setToast } from '@repo/ui/components/Toaster'
import { useEffect } from 'react'

export const useToaster = (toast: Toast) => {
  useEffect(() => {
    if (!toast) return

    const { type, title } = toast

    const options = {
      id: toast.id ?? String(Date.now()),
      description: toast.description
    }

    setToast[type](title, options)
  }, [toast])
}
