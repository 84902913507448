import { Toaster as Sonner, type ToastT, toast } from 'sonner'

import { Icon } from './Icon'
import { Spinner } from './Spinner'

type ToasterProps = React.ComponentProps<typeof Sonner>

type Toast = {
  id?: ToastT['id']
  type: Extract<Required<ToastT>['type'], 'success' | 'error' | 'warning' | 'info'>
  title: string
  description?: string
}

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group flex flex-col items-center"
      position="bottom-center"
      icons={{
        success: <Icon name={'circle-check'} size="md" />,
        loading: <Spinner size="sm" />,
        error: <Icon name="warning" size="lg" />
      }}
      toastOptions={{
        unstyled: true,
        duration: 5000,
        classNames: {
          success: 'bg-success-700 text-white',
          error: 'bg-destructive text-white',
          loading: 'bg-primary text-white',
          warning: 'bg-warning-700 text-warning-100',
          info: 'bg-brand text-white',
          title: 'font-bold',
          toast:
            'shadow-lg text-sm px-4 py-2 flex items-center gap-2 rounded-md min-w-72 font-sans',
          description: 'group-[.toast]:text-muted-foreground text-sm',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground'
        }
      }}
      {...props}
    />
  )
}

export { type Toast, toast, Toaster }
